import { createStore } from 'vuex'

export default createStore({
  state() {
    // 所有在 store 裏的資料
    return {
      headNav: [
        {
          title: '關於東南',
          type: 'dropdown',
          path: 'about',
          target: '',
          position: '',
          url: '',
          list: [
            {
              title: '校史簡介',
              path: 'introduction',
              item: [
                { title: '本校簡史', path: 'history', target: '_self', position: 'internal', url: '' },
                { title: '組織規程', path: 'organizational_rules', target: '_self', position: 'internal', url: '' },
                { title: '特色發展', path: 'feature', target: '_self', position: 'internal', url: '' },
                { title: '基本素養', path: 'literacy', target: '_self', position: 'internal', url: '' }
              ]
            },
            {
              title: '東南大家長',
              path: 'family',
              item: [
                { title: '創辦人興學理念', path: 'concept', target: '_self', position: 'internal', url: '' },
                { title: '董事會', path: 'board_of_directors', target: '_self', position: 'internal', url: '' },
                { title: '歷任校長', path: 'former_principals', target: '_self', position: 'internal', url: '' },
                { title: '現任校長', path: '', target: '_blank', position: 'external', url: 'https://web.tnu.edu.tw/principal/' }
              ]
            },
            {
              title: '東南資料',
              path: 'information',
              item: [
                { title: '校務資訊公開專區', path: '', target: '_blank', position: 'external', url: 'https://web.tnu.edu.tw/campusdata/' },
                { title: '基本資料', path: 'basic_information', target: '_self', position: 'internal', url: '' },
                { title: '象徵(校訓、校徽、校歌)', path: 'symbol', target: '_self', position: 'internal', url: '' },
                { title: '今古東南系列', path: 'nostalgia', target: '_self', position: 'internal', url: '' }
              ]
            }
          ]
        },
        {
          title: '學術單位',
          type: 'dropdown',
          path: 'academic',
          target: '',
          position: '',
          url: '',
          list: [
            {
              title: '工程與電資學院',
              path: '',
              item: [
                { title: '工程與電資學院網站', path: '', target: '_blank', position: 'external', url: 'https://ce.tnu.edu.tw/' },
                { title: '機械工程系(含車輛組、碩士班)', path: '', target: '_blank', position: 'external', url: 'https://me.tnu.edu.tw/' },
                { title: '營建與空間設計系(含碩士班)', path: '', target: '_blank', position: 'external', url: 'https://ct.tnu.edu.tw/' },
                { title: '電子工程系', path: '', target: '_blank', position: 'external', url: 'https://ee.tnu.edu.tw/' },
                { title: '電機工程系(含碩士班)', path: '', target: '_blank', position: 'external', url: 'https://eee.tnu.edu.tw/' }
              ]
            },
            {
              title: '觀餐休閒與管理學院',
              path: '',
              item: [
                { title: '觀餐休閒與管理學院網站', path: '', target: '_blank', position: 'external', url: 'https://chl.tnu.edu.tw/' },
                { title: '餐旅管理系', path: '', target: '_blank', position: 'external', url: 'https://hm.tnu.edu.tw/' },
                { title: '休閒事業管理系', path: '', target: '_blank', position: 'external', url: 'https://la.tnu.edu.tw/' },
                { title: '觀光系', path: '', target: '_blank', position: 'external', url: 'https://tourism.tnu.edu.tw/' },
                { title: '應用英語系', path: '', target: '_blank', position: 'external', url: 'https://ae.tnu.edu.tw/' },
                { title: '產業經營管理研究所', path: '', target: '_blank', position: 'external', url: 'https://ie.tnu.edu.tw/' },
                { title: '表演藝術系', path: '', target: '_blank', position: 'external', url: 'https://pad.tnu.edu.tw' }
              ]
            },
            {
              title: '創新設計學院',
              path: '',
              item: [
                { title: '創新設計學院網站', path: '', target: '_blank', position: 'external', url: 'https://cid.tnu.edu.tw/' },
                { title: '數位媒體設計系', path: '', target: '_blank', position: 'external', url: 'https://dmd.tnu.edu.tw/' },
                { title: '創意產品設計系', path: '', target: '_blank', position: 'external', url: 'https://cpd.tnu.edu.tw/' },
                { title: '室內設計系', path: '', target: '_blank', position: 'external', url: 'https://id.tnu.edu.tw/' },
                { title: '數位遊戲設計系', path: '', target: '_blank', position: 'external', url: 'https://dgd.tnu.edu.tw/' }
              ]
            },
            {
              title: '通識教育',
              path: '',
              item: [
                { title: '通識教育中心', path: '', target: '_blank', position: 'external', url: 'https://ge.tnu.edu.tw/' }
              ]
            }
          ]
        },
        {
          title: '行政單位',
          type: 'dropdown',
          path: 'administrative',
          target: '',
          position: '',
          url: '',
          list: [
            {
              title: '校務行政與研究發展',
              path: '',
              item: [
                { title: '校長室', path: '', target: '_blank', position: 'external', url: 'https://web.tnu.edu.tw/principal/' },
                { title: '副校長室', path: '', target: '_blank', position: 'external', url: 'https://web.tnu.edu.tw/viceprincipal_tung/' },
                { title: '秘書室', path: '', target: '_blank', position: 'external', url: 'https://sr.tnu.edu.tw/' },
                { title: '人事室', path: '', target: '_blank', position: 'external', url: 'https://pr.tnu.edu.tw/' },
                { title: '研究發展處', path: '', target: '_blank', position: 'external', url: 'https://rd.tnu.edu.tw/' },
                { title: '環境教育機構', path: '', target: '_blank', position: 'external', url: 'https://rec.tnu.edu.tw/zh_tw/eei/eei_p1' }
              ]
            },
            {
              title: '教學與學習資源',
              path: '',
              item: [
                { title: '教務處', path: '', target: '_blank', position: 'external', url: 'https://aa.tnu.edu.tw/' },
                { title: '學生事務處', path: '', target: '_blank', position: 'external', url: 'https://sa.tnu.edu.tw/' },
                { title: '圖書資訊處', path: '', target: '_blank', position: 'external', url: 'https://oli.tnu.edu.tw/' },
                { title: '體育室', path: '', target: '_blank', position: 'external', url: 'https://sports.tnu.edu.tw/' }
              ]
            },
            {
              title: '總務會計',
              path: '',
              item: [
                { title: '總務處', path: '', target: '_blank', position: 'external', url: 'https://oga.tnu.edu.tw/' },
                { title: '會計室', path: '', target: '_blank', position: 'external', url: 'https://ar.tnu.edu.tw/' }
              ]
            },
            {
              title: '招生與國際業務',
              path: '',
              item: [
                { title: '招生與選才中心', path: '', target: '_blank', position: 'external', url: 'https://ac.tnu.edu.tw/' },
                { title: '國際事務處', path: '', target: '_blank', position: 'external', url: 'https://ia.tnu.edu.tw/' },
                { title: '國際專修部', path: '', target: '_blank', position: 'external', url: 'https://ifp.tnu.edu.tw/' },
                { title: '新南向國際學生產學合作專班', path: '', target: '_blank', position: 'external', url: 'https://web.tnu.edu.tw/southeast_asian/' },
                { title: '推廣教育中心', path: '', target: '_blank', position: 'external', url: 'https://rec.tnu.edu.tw/' }
              ]
            }
          ]
        },
        {
          title: '招生資訊',
          type: 'link',
          path: '',
          target: '_blank',
          position: 'external',
          url: 'https://ac.tnu.edu.tw/',
          list: []
        },
        {
          title: '交通資訊',
          type: 'dropdown',
          path: '',
          target: '',
          position: '',
          url: '',
          list: [
            {
              title: '來校交通',
              path: '',
              item: [
                { title: '接駁車資訊', path: 'shuttle_service', target: '_self', position: 'internal', url: '' },
                { title: '來校交通指引', path: 'traffic_directions', target: '_self', position: 'internal', url: '' },
                { title: '地理位置圖(Google Map)', path: 'map', target: '_self', position: 'internal', url: '' },

              ]
            },
            {
              title: '校區相關',
              path: '',
              item: [
                { title: '校區平面圖', path: 'campus_layout_map', target: '_self', position: 'internal', url: '' },
                { title: '校園疏散避難路線圖', path: 'evacuation_route_map', target: '_self', position: 'internal', url: '' },

              ]
            },
            {
              title: '交通安全',
              path: '',
              item: [
                { title: '交通安全資訊', path: '', target: '_blank', position: 'external', url: 'https://sa.tnu.edu.tw/zh_tw/page5/page503' },
              ]
            },
          ]
        },
        {
          title: '校友會',
          type: 'link',
          path: '',
          target: '_blank',
          position: 'external',
          url: 'https://alu.tnu.edu.tw/',
          list: []
        },
        {
          title: '募款',
          type: 'link',
          path: 'donation',
          target: '_self',
          position: 'internal',
          url: '',
          list: []
        },
        {
          title: '東南電子報',
          type: 'link',
          path: '',
          target: '_blank',
          position: 'external',
          url: 'https://web.tnu.edu.tw/epaper/',
          list: []
        },
        {
          title: '活動與報名',
          type: 'link',
          path: 'event_list',
          target: '_self',
          position: 'internal',
          url: '',
          list: []
        },
        // {
        //   title: '活動報名',
        //   type: 'link',
        //   path: '',
        //   target: '_blank',
        //   position: 'external',
        //   url: 'http://qna.tnu.edu.tw/list/',
        //   list: []
        // },
      ],
      isLoading: false,
    }
  },
  actions: {
    // 負責觸發 mutations
    // 可處理非同步程式（e.g: 打 API）
    // setLoading(context, payload) {
    //     context.commit('Set_Loading', payload);
    // },
  },
  mutations: {
    // 負責改變 state 裏的資料
    setIsLoading(state, payload) {
      state.isLoading = payload
    },
  },
  getters: {
    // 像 computed 一樣，運算處理 state 資料
    getIsLoading: (state) => {
      return state.isLoading
    },
    getHeadNav: (state) => {
      return state.headNav
    },
  },
  modules: {
    // 按需求分拆 module
    // 每個 module 都有自己的state, actions, mutations, getters, modules
  }
})
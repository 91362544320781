import { createWebHistory, createRouter } from "vue-router";
// import store from '@/store/index.js';

const routes = [
  {
    path: "/",
    name: "Index",
    component: () => import("@/views/Index.vue"),
    meta: {
      title: '東南科技大學官方網站'
    }
  },
  //本校簡史
  {
    path: "/history",
    name: "history",
    component: () => import("@/views/History.vue"),
    meta: {
      title: '東南科技大學官方網站-本校簡史'
    }
  },
  //組織規程
  {
    path: "/organizational_rules",
    name: "organizational_rules",
    component: () => import("@/views/OrganizationalRules.vue"),
    meta: {
      title: '東南科技大學官方網站-組織規程'
    }
  },
  //特色發展
  {
    path: "/feature",
    name: "feature",
    component: () => import("@/views/Feature.vue"),
    meta: {
      title: '東南科技大學官方網站-特色發展'
    }
  },
  //基本素養
  {
    path: "/literacy",
    name: "literacy",
    component: () => import("@/views/Literacy.vue"),
    meta: {
      title: '東南科技大學官方網站-基本素養'
    }
  },
  //創辦人興學理念
  {
    path: "/concept",
    name: "concept",
    component: () => import("@/views/Concept.vue"),
    meta: {
      title: '東南科技大學官方網站-創辦人興學理念'
    }
  },
  //董事會
  {
    path: "/board_of_directors",
    name: "board_of_directors",
    component: () => import("@/views/BoardOfDirectors.vue"),
    meta: {
      title: '東南科技大學官方網站-董事會'
    }
  },
  //歷任校長
  {
    path: "/former_principals",
    name: "former_principals",
    component: () => import("@/views/FormerPrincipals.vue"),
    meta: {
      title: '東南科技大學官方網站-歷任校長'
    }
  },
  //基本資料
  {
    path: "/basic_information",
    name: "basic_information",
    component: () => import("@/views/BasicInformation.vue"),
    meta: {
      title: '東南科技大學官方網站-基本資料'
    }
  },
  //象徵(校訓、校徽、校歌)
  {
    path: "/symbol",
    name: "symbol",
    component: () => import("@/views/Symbol.vue"),
    meta: {
      title: '東南科技大學官方網站-象徵(校訓、校徽、校歌)'
    }
  },
  //今古東南系列
  {
    path: "/nostalgia",
    name: "nostalgia",
    component: () => import("@/views/Nostalgia.vue"),
    meta: {
      title: '東南科技大學官方網站-今古東南系列'
    }
  },
  //接駁車資訊
  {
    path: "/shuttle_service",
    name: "shuttle_service",
    component: () => import("@/views/ShuttleService.vue"),
    meta: {
      title: '東南科技大學官方網站-接駁車資訊'
    }
  },
  //舊版接駁車轉址
  {
    path: "/zh_tw/traffic11/tra/traffic01",
    redirect: '/shuttle_service',
    meta: {
      title: '東南科技大學官方網站-舊版接駁車轉址'
    }
  },
  //來校交通指引
  {
    path: "/traffic_directions",
    name: "traffic_directions",
    component: () => import("@/views/TrafficDirections.vue"),
    meta: {
      title: '東南科技大學官方網站-來校交通指引'
    }
  },
  //舊版來校交通指引
  {
    path: "/zh_tw/traffic11/tra/traffic04",
    redirect: '/traffic_directions',
    meta: {
      title: '東南科技大學官方網站-舊版來校交通指引'
    }
  },
  //地理位置圖
  {
    path: "/map",
    name: "map",
    component: () => import("@/views/Map.vue"),
    meta: {
      title: '東南科技大學官方網站-地理位置圖'
    }
  },
  //舊版地理位置圖
  {
    path: "/zh_tw/traffic11/tra/traffic02",
    redirect: '/map',
    meta: {
      title: '東南科技大學官方網站-舊版地理位置圖'
    }
  },
  //校區平面圖
  {
    path: "/campus_layout_map",
    name: "campus_layout_map",
    component: () => import("@/views/CampusLayoutMap.vue"),
    meta: {
      title: '東南科技大學官方網站-校區平面圖'
    }
  },
  //舊版校區平面圖
  {
    path: "/zh_tw/traffic11/area/space01",
    redirect: '/campus_layout_map',
    meta: {
      title: '東南科技大學官方網站-舊版校區平面圖'
    }
  },
  //校園疏散避難路線圖
  {
    path: "/evacuation_route_map",
    name: "evacuation_route_map",
    component: () => import("@/views/EvacuationRouteMap.vue"),
    meta: {
      title: '東南科技大學官方網站-校園疏散避難路線圖'
    }
  },
  //舊版校區平面圖
  {
    path: "/zh_tw/traffic11/area/space02",
    redirect: '/evacuation_route_map',
    meta: {
      title: '東南科技大學官方網站-舊版校區平面圖'
    }
  },
  //募款
  {
    path: "/donation",
    name: "donation",
    component: () => import("@/views/Donation.vue"),
    meta: {
      title: '東南科技大學官方網站-募款'
    }
  },
  //網站地圖
  {
    path: "/sitemap",
    name: "sitemap",
    component: () => import("@/views/Sitemap.vue"),
    meta: {
      title: '東南科技大學官方網站-網站地圖'
    }
  },
  //舊版網站地圖
  {
    path: "/zh_tw/sitemap",
    redirect: '/sitemap',
    meta: {
      title: '東南科技大學官方網站-舊版網站地圖'
    }
  },
  //行事曆
  {
    path: "/calendar",
    name: "calendar",
    component: () => import("@/views/Calendar.vue"),
    meta: {
      title: '東南科技大學官方網站-行事曆'
    }
  },
  //舊版行事曆
  {
    path: "/page/calendar",
    redirect: '/calendar',
    meta: {
      title: '東南科技大學官方網站-舊版行事曆'
    }
  },
  //在校學生
  {
    path: "/student",
    name: "student",
    component: () => import("@/views/Student.vue"),
    meta: {
      title: '東南科技大學官方網站-在校學生'
    }
  },
  //舊版在校學生
  {
    path: "/page/student",
    redirect: '/student',
    meta: {
      title: '東南科技大學官方網站-舊版在校學生'
    }
  },
  //教職員工
  {
    path: "/staff",
    name: "staff",
    component: () => import("@/views/Staff.vue"),
    meta: {
      title: '東南科技大學官方網站-教職員工'
    }
  },
  //舊版教職員工
  {
    path: "/page/staff",
    redirect: '/staff',
    meta: {
      title: '東南科技大學官方網站-舊版教職員工'
    }
  },
  //畢業校友
  {
    path: "/alumni",
    name: "alumni",
    component: () => import("@/views/Alumni.vue"),
    meta: {
      title: '東南科技大學官方網站-畢業校友'
    }
  },
  //舊版畢業校友
  {
    path: "/page/alumni",
    redirect: '/alumni',
    meta: {
      title: '東南科技大學官方網站-舊版畢業校友'
    }
  },
  //未來學生
  {
    path: "/future",
    name: "future",
    component: () => import("@/views/Future.vue"),
    meta: {
      title: '東南科技大學官方網站-未來學生'
    }
  },
  //舊版未來學生
  {
    path: "/page/future",
    redirect: '/future',
    meta: {
      title: '東南科技大學官方網站-舊版未來學生'
    }
  },
  //貴賓訪客
  {
    path: "/guest",
    name: "guest",
    component: () => import("@/views/Guest.vue"),
    meta: {
      title: '東南科技大學官方網站-貴賓訪客'
    }
  },
  //舊版未來學生
  {
    path: "/page/guest",
    redirect: '/guest',
    meta: {
      title: '東南科技大學官方網站-舊版未來學生'
    }
  },
  //焦點新聞列表
  {
    path: "/news_list/:page",
    name: "newsList",
    component: () => import("@/views/NewsList.vue"),
    meta: {
      title: '東南科技大學官方網站-焦點新聞列表'
    }
  },
  //最新公告列表
  {
    path: "/post_list/:type/:page",
    name: "postList",
    component: () => import("@/views/PostList.vue"),
    meta: {
      title: '東南科技大學官方網站-最新公告列表'
    }
  },
  //活動報名
  {
    path: "/event_list",
    name: "eventList",
    component: () => import("@/views/EventList.vue"),
    meta: {
      title: '東南科技大學官方網站-活動公告/報名'
    }
  },
  //隱私權聲明
  {
    path: "/privacy",
    name: "privacy",
    component: () => import("@/views/Privacy.vue"),
    meta: {
      title: '東南科技大學官方網站-隱私權聲明'
    }
  },
  //找不到網頁404
  {
    path: "/404",
    name: "Error404",
    component: () => import("@/views/Error404.vue"),
    meta: {
      title: '東南科技大學官方網站-找不到網頁'
    }
  },
  //找不到路徑全部導向404
  {
    path: "/:catchAll(.*)",
    redirect: '/404',
    meta: {
      title: '東南科技大學官方網站-找不到網頁'
    }
  }
  // {
  //   path: "/login",
  //   name: "Login",
  //   component: Login
  // },
  // {
  //   path: '/content',
  //   component: Content,
  //   children: [
  //     {
  //       path: "/content/dashboard",
  //       name: "Dashboard",
  //       component: Dashboard,
  //       meta: {
  //         needLogin: true
  //       }
  //     }
  //   ]
  // },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  // 防止瀏覽器紀錄上次滾動位置
  scrollBehavior() {
    return { top: 0 };
  }
});
// router 後動作
router.beforeEach((to, from, next) => {
  /* 重新命名 PAGE TITLE START */
  window.document.title = to.meta.title;
  /* 重新命名 PAGE TITLE END */


  /* 重置彈跳MODAL START */
  let modalBackground = document.querySelector('.modal-backdrop')
  if (modalBackground) {
    modalBackground.remove()
  }
  // 獲取body元素
  var bodyElement = document.body;

  // 檢查是否存在class
  if (bodyElement.classList.contains("modal-open")) {
    // 移除class
    bodyElement.classList.remove("modal-open");
  }

  // 檢查是否存在style屬性
  if (bodyElement.hasAttribute("style")) {
    // 移除style屬性
    bodyElement.removeAttribute("style");
  }
  /* 重置彈跳MODAL END */

  next();
})

// router.beforeResolve( async to=>{
//   if(to.meta.needLogin){
//       const token = store.getters.getLoginToken;
//       if(token === "" || !token) return {name: "Login"}
//   }
// })

export default router;
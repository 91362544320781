<template>
  <router-view class="app" />
  <loading :active="isLoading" :can-cancel="false" :opacity="0.7" />
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";

export default {
  name: "App",
  components: { Loading },
  computed: {
    isLoading() {
      return this.$store.state.isLoading;
    },
    getDeptName() {
      return this.$store.getters.getDeptName(this.$route.params);
    },
  },
  watch: {
    $route() {
      document.activeElement.blur();
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    },
  },
};
</script>

<style>
</style>
